<template>
  <div>
    <ul class="nav flex-column">
      <li class="nav-label" v-if="isGranted('ROLE_CEO_ASSISTANT')">
        <label class="content-label">ADMIN</label>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_FEE_SPLIT') || isGranted('ROLE_ADD_USER') || isGranted('ROLE_ANALYST')">
        <router-link :to="{ name: 'users' }" class="nav-link" :class="{ active: currentPage === 'users' }">
          <img src="@/assets/icons/Icon-Staff.svg" alt="Staff"/>
          Users
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_AUDIT_TRAIL')">
        <router-link :to="{ name: 'activity' }" class="nav-link" :class="{ active: currentPage === 'activity' }">
          <img src="@/assets/icons/Icon-BlogArticle.svg" alt="Activity"/>
          Activity
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_AGREEMENT_PROCESS')">
        <a :href="backendUrl('/mlo/agreement/')" class="nav-link">
          <img src="@/assets/icons/Icon-Onboarding.svg" alt="Icon Onboarding"/>
          Onboarding
          <div class="nav-notification bg-primary" v-if="counters.agreements > 0">
            {{ counters.agreements }}
          </div>
        </a>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO')">
        <a class="nav-link" :href="backendUrl('/mlo/lendingtree/')">
          <img src="@/assets/icons/Icon-LendingTree.svg" alt="Marketing Tool"/>
          Marketing Tool
        </a>
      </li>
      <li class="nav-item nav-item-dropdown" @click="toggleDropdown"
          v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">
        <a class="nav-link" href="#">
          <img src="@/assets/icons/icon-credit-report.svg" alt="Billing tools"/>
          Billing tools
        </a>
      </li>

      <ul class="nav flex-column ps-2" v-if="ui.toggleBillingDropdown">
        <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">
          <a class="nav-link" :href="backendUrl('/mlo/credit_report/')">
            <img src="@/assets/icons/icon-credit-report.svg" alt="Credit Report Billing Tool"/>
            Credit Report Billing Tool
          </a>
        </li>
        <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">
          <a class="nav-link" :href="backendUrl('/mlo/credit-card-expense/')">
            <img src="@/assets/icons/icon-credit-report.svg" alt="Credit Report Billing Tool"/>
            Credit Card Expenses
          </a>
        </li>
        <li class="nav-item" v-if="isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BOOKKEEPER')">
          <router-link :to="{ name: 'bank_rate_lead_tool' }" class="nav-link"
                       :class="{ active: currentPage === 'bank_rate_lead_tool' }">
            <img src="@/assets/icons/bank-rate-lead-tool-icon.png" alt="Staff"/>
            BankRate lead tool
          </router-link>
        </li>
        <li class="nav-item" v-if="isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BOOKKEEPER')">
          <router-link :to="{ name: 'bank_rate_lead_tool_sync' }" class="nav-link"
                       :class="{ active: currentPage === 'bank_rate_lead_tool_sync' }">
            <img src="@/assets/icons/bank-rate-lead-tool-icon.png" alt="Staff"/>
            BankRate lead tool (sync)
          </router-link>
        </li>
        <li class="nav-item" v-if="isGranted('ROLE_CEO')">
          <router-link :to="{ name: 'lower_my_bills_tool' }" class="nav-link"
                       :class="{ active: currentPage === 'lower_my_bills_tool' }">
            <img src="@/assets/icons/Icon-WithdrawalRequests.svg" alt="LowerMyBills billing tool"/>
            LowerMyBills billing tool
          </router-link>
        </li>
      </ul>

      <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BRANCH_MANAGER')">
        <a class="nav-link" :href="backendUrl('/mlo/branch/')">
          <img src="@/assets/icons/Icon-BranchManagement.svg" alt="Branch Management"/>
          Branch Management
        </a>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO')">
        <a class="nav-link" :href="backendUrl('/mlo/front/blog/article/')">
          <img src="@/assets/icons/Icon-BlogArticle.svg" alt="Blog Articles"/>
          Blog Articles
        </a>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BRANCH_MANAGER')">
        <router-link :to="{ name: 'reports' }" class="nav-link" :class="{ active: currentPage === 'reports' }">
          <img src="@/assets/icons/Icon-reports.svg" alt="Reports"/>
          Reports
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_EXPENSE_TYPE_CRUD')">
        <a class="nav-link" :href="backendUrl('/mlo/expense-category/')">
          <img src="@/assets/icons/Icon-ExpensesCategories.svg" alt="Expense Categories"/>
          Expense Categories
        </a>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_STAGE_CRUD')">
        <a class="nav-link" :href="backendUrl('/mlo/stage/')">
          <img src="@/assets/icons/Icon-TouchFile.svg" alt="Loan Stages"/>
          Loan Stages
        </a>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">
        <router-link :to="{ name: 'positions' }" class="nav-link" :class="{ active: currentPage === 'positions' }">
          <img src="@/assets/icons/Icon-Rankings.svg" alt="Positions"/>
          Positions
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link" :href="backendUrl('/mlo/timer/')"
           v-if="isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_PROCESSING_MANAGER')">
          <img src="@/assets/icons/stopwatch-white.svg" alt="Time Clock"/>
          Time Clock
        </a>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO')">
        <router-link :to="{ name: 'email_logs' }" class="nav-link" :class="{ active: currentPage === 'email_logs' }">
          <img src="@/assets/icons/Icon-email-log.svg" alt="Email Log"/>
          Email Logs
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_IREFI_CONFIG')">
        <a class="nav-link" :href="backendUrl('/mlo/irefi/config/')">
          <img src="@/assets/icons/Icon-Settings.svg" alt="Settings"/>
          iRefi Configuration
        </a>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO_ASSISTANT')">
        <router-link :to="{ name: 'config_landing' }" class="nav-link"
                     :class="{ active: currentPage === 'config_landing' }">
          <img src="@/assets/icons/Icon-Settings.svg" alt="Settings"/>
          Landing Configuration
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO_ASSISTANT')">
        <router-link :to="{ name: 'states' }" class="nav-link"
                     :class="{ active: currentPage === 'states' }">
          <img src="@/assets/icons/Icon-Settings.svg" alt="Settings"/>
          States
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO_ASSISTANT')">
        <router-link :to="{ name: 'branches' }" class="nav-link"
                     :class="{ active: currentPage === 'branches' }">
          <img src="@/assets/icons/Icon-Settings.svg" alt="Settings"/>
          Branches
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_SALES_TRAINER')">
        <a class="nav-link" :href="backendUrl('/mlo/settings/')">
          <img src="@/assets/icons/Icon-Settings.svg" alt="Settings"/>
          Settings
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AdminLinksList",
  props: {counters: Object},
  data() {
    return {
      ui: {
        toggleBillingDropdown: false,
      }
    }
  },
  computed: {
    currentPage() {
      return this.$route.name;
    },
  },
  methods: {
    toggleDropdown() {
      this.ui.toggleBillingDropdown = !this.ui.toggleBillingDropdown;
    }
  }
};
</script>
