<template>
  <div class="div">
    <div class="position-absolute dropdown-note dropdown-tracker" id="dropdownTracker" v-click-outside="closeTimer">
      <div class="mb-5">
        <h3 class="text-center">Time Tracker</h3>
      </div>
      <div class="text-center" v-if="ui.loading === true">
        <div class="spinner-border mt-2 mb-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="timer-buttons">
          <button class="btn" :class="{'btn-primary': timerData.isTracking === false}" :disabled="ui.clockOut || !timerData.isTracking" @click="stopTrackTime">
            <span v-if="ui.clockOut"
                  class="spinner-border spinner-border-sm"
                  role="status" aria-hidden="true">
            </span>
            <span v-else>Clock Out</span>
          </button>
          <button class="btn" :class="{'btn-primary': timerData.isTracking}" :disabled="ui.clockIn || timerData.isTracking" @click="startTrackTime">
            <span v-if="ui.clockIn"
                  class="spinner-border spinner-border-sm"
                  role="status" aria-hidden="true">
            </span>
            <span v-else>Clock In</span>
          </button>
        </div>
        <h6 class="text-center mt-4">Current Session:</h6>
        <div class="timer-round" :class="{active: timerData.isTracking === true}">
          <h3 class="timer-current-session" data-seconds="0">
            {{ convertSecondsToHours(timerData.currentSessionTime) }} hrs
            {{ convertSecondsToMinutes(timerData.currentSessionTime) }} m
          </h3>
        </div>
        <div class="timer-info mt-5">
          <div class="row">
            <div class="col-7">
              <span class="color-light-grey">Today:</span>
              <h3 v-if="ui.isOvertime">
                8 hours +
                {{ convertSecondsToHours(timerData.overtimeSeconds) }} hrs
                {{ convertSecondsToMinutes(timerData.overtimeSeconds) }} m overtime
              </h3>
              <h3 v-else>
                {{ convertSecondsToHours(timerData.todaySeconds) }} hrs
                {{ convertSecondsToMinutes(timerData.todaySeconds) }} m
              </h3>
            </div>
            <div class="col-5 text-end">
              <span class="color-light-grey">This week:</span>
              <h3>
                {{ convertSecondsToHours(timerData.weekSeconds) }} hrs
                {{ convertSecondsToMinutes(timerData.weekSeconds) }} m
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  name: 'TimeTrackerModal',
  directives: {clickOutside: vClickOutside.directive},
  data() {
    return {
      workDayInSeconds: 28800,
      ui: {
        loading: false,
        clockIn: false,
        clockOut: false,
        tracking: false,
        isOvertime: false
      },
      timerData: {
        isTracking: null,
        currentSessionTime: 0,
        todaySeconds: 0,
        overtimeSeconds: 0,
        weekSeconds: 0
      }
    }
  },
  methods: {
    convertSecondsToHours(seconds) {
      return Math.floor(seconds / 3600)
    },
    convertSecondsToMinutes(seconds) {
      return Math.floor(seconds % 3600 / 60);
    },
    startTrackTime() {
      if (!this.timerData.isTracking) {
        this.ui.clockIn = true
        this.$http.post('/api/v1/users/timer/start')
          .then(() => this.timerData.isTracking = true)
          .finally(() => this.ui.clockIn = false)
      }
    },
    stopTrackTime() {
      if (this.timerData.isTracking) {
        this.ui.clockOut = true
        this.$http.post('/api/v1/users/timer/stop')
          .then(() => this.timerData.isTracking = false)
          .finally(() => {
            this.getTimerData(false)
            this.ui.clockOut = false
          })
      }
    },
    getTimerData(isLoading = true) {
      if (isLoading) {
        this.ui.loading = true
      }

      this.$http.get('/api/v1/users/timer/data')
      .then((res) => {
        this.timerData = res.data

        this.ui.isOvertime = this.timerData.todaySeconds > this.workDayInSeconds
        if (this.ui.isOvertime) {
          this.timerData.overtimeSeconds = this.timerData.todaySeconds - this.workDayInSeconds
        }
      })
      .finally(() => this.ui.loading = false)
    },
    closeTimer() {
      this.$emit('close')
    }
  },
  beforeMount() {
    this.getTimerData();
  },
}
</script>

<style lang="scss">
#dropdownTracker{
  @media (max-width: 568px) {
    width: max-content;
    left: 0;
    transform: translateX(-20%);
  }
}

.dropdown-tracker {
  padding: 30px;
  height: auto !important;
  min-height: 540px;

  .timer-round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 170px;
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 50%;
    margin: 20px auto 0;
    transition: all .3s;
    &.active {
      border: 1px solid #23b708;
      h3 {
        color: #23b708;
      }
    }
    h3 {
      color: #a2a0a0;
      transition: all .3s;
    }
  }
  button {
    font-size: .8rem;
  }
  .timer-buttons {
    width: 260px;
    margin: 0 auto;
    padding: 7px 10px;
    border-radius: 8px;
    border: 1px solid #d3d3d3;
    button {
      width: 50%;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .timer-info {
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    padding: 5px 10px;
    h3 {
      color: #000;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }
  }
}
</style>
