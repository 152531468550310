<template>
  <div class="cursor-default">
    <div class="position-absolute dropdown-note" id="headerNoteModal" v-click-outside="closeNote">
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-show="ui.loading === false">
        <div class="form-group full-width" :class="[tab === 'addTask' ? 'd-none' : 'd-block']">
          <multiselect v-model="borrower" :options="borrowers" :preselectFirst="true" @select="updateBorrowerData"
                       class="w-100 select-borrower" placeholder="Select File" label="name" track-by="id"/>
        </div>
        <div class="modal-body">
          <div class="page-tabs d-flex justify-content-between mt-2 p-0 align-items-center"
               :class="[(tab === 'addTask') ? 'd-none' : 'd-block']">
            <ul class="tab-list p-0">
              <li :class="{ active: tab === 'Notes' }" @click="setTab('Notes')">Notes</li>
              <li :class="{ active: tab === 'Todo' }" @click="setTab('Todo')">Todo</li>
              <li :class="{ active: tab === 'Attachments' }" @click="setTab('Attachments')">Attachments</li>
            </ul>
            <button class="btn btn-link" @click="setTab('addTask')">Add Task</button>
          </div>
          <div id="modal-body-scroll" v-show="tab !== 'addTask'">
            <div>
              <div class="modal-body-container" v-show="tab === 'Notes'">
                <div class="note note-click mt-3" v-for="(note, index) in notes" :key="index">
                  <div class="d-flex">
                    <img :src="agentPhoto(note.agent.photo)" class="avatar" alt="Avatar" v-if="note.agent">
                    <div class="w-100 note-body ps-2">
                      <div class="task-header d-flex justify-content-between" v-if="note.agent">
                        <span class="task-header-assigner">{{ note.agent.fullName }}</span>
                        <span class="task-date">{{ note.createdAtForHumans }}</span>
                      </div>
                      <div v-if="note.type === 'task'" class="cursor-pointer" @click="toggleTaskModal(note.id, 'sub-task')">
                        <span class="assigned-task">Assigned task</span>
                        <div class="task-body">
                          <div class="d-flex">
                            <div class="form-group form-check-round me-2">
                              <input type="checkbox" class="form-check-input" aria-label="status" :checked="note.task.status === 'done'">
                            </div>
                            <div class="task-info">
                              <div class="task-info-name">{{ note.task.title }}</div>
                              <div class="d-flex mt-1">
                                <div class="task-comments">
                                  <img src="@/assets/icons/icon-comment-18.svg" alt="Comments">
                                  <span>{{ note.task.comments.length }}</span>
                                </div>
                                <div class="task-comments ms-3">
                                  <img src="@/assets/icons/icon-attachment-18.svg" alt="Files">
                                  <span>{{ note.task.commentsTotal }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="note.type === 'edit'">
                        <span class="assigned-task">Application has been updated:</span>
                        <div class="app-updated d-flex align-items-center justify-content-between border p-2"
                             v-for="(log, index) in note.changes" :key="index">
                          <div>{{ log.property }}</div>
                          <div>
                            <img src="@/assets/icons/icon-value-removed.svg" alt="">
                            <span>{{ log.oldValue }}</span>
                          </div>
                          <div>
                            <img src="@/assets/icons/icon-value-removed-icon-value-added.svg" alt="">
                            <span>{{ log.newValue }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="note.type === 'note'">
                        <span class="assigned-task">{{ note.message }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex" v-if="note.type === 'uwm_note'">
                    <img src="@/assets/images/male.png" class="avatar" alt="Avatar">
                    <div class="w-100 note-body ps-2">
                      <div class="task-header d-flex justify-content-between">
                        <span class="task-header-assigner">Application has been updated</span>
                        <span class="task-date">{{ note.createdAtForHumans }}</span>
                      </div>
                    </div>
                    <div>
                      <span class="assigned-task">{{ note.message }}</span>
                    </div>
                  </div>
                  <uwm-notes v-if="note.type === 'uwm_sync'" :note="note"/>
                </div>
              </div>
              <div class="modal-body-container" v-show="tab === 'Todo'">
                <div class="note note-click mt-3 cursor-pointer" v-for="(task, index) in tasks" :key="index" @click="toggleTaskModal(task.id, 'sub-task')">
                  <div class="d-flex">
                    <img :src="agentPhoto(task.assignedBy.photo)" class="avatar" alt="Avatar">
                    <div class="w-100 note-body ps-2">
                      <div class="task-header d-flex justify-content-between">
                        <span class="task-header-assigner">{{ task.assignedBy.fullName }}</span>
                        <span class="task-date">{{ task.createdAtForHumans }}</span>
                      </div>
                      <span class="assigned-task">Assigned task</span>
                      <div class="task-body">
                        <div class="d-flex">
                          <div class="form-group form-check-round me-2">
                            <input type="checkbox" class="form-check-input" aria-label="status" :checked="task.status === 'done'">
                          </div>
                          <div class="task-info">
                            <div class="task-info-name">{{ task.title }}</div>
                            <div class="d-flex mt-1">
                              <div class="task-comments">
                                <img src="@/assets/icons/icon-comment-18.svg" alt="Comments icon">
                                <span>{{ task.comments.length }}</span>
                              </div>
                              <div class="task-comments ms-3">
                                <img src="@/assets/icons/icon-attachment-18.svg" alt="Files">
                                <span>{{ task.commentsTotal }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-body-container" v-show="tab === 'Attachments'">
                <div class="note mt-3" v-for="(item, index) in attachments" :key="index">
                  <div class="d-flex">
                    <img :src="agentPhoto(item.author.photo)" class="avatar" :alt="item.author.fullName">
                    <div class="w-100 note-body ps-2">
                      <div class="task-header d-flex justify-content-between">
                        <span class="task-header-assigner">{{ item.author.fullName }}</span>
                        <span class="task-date">{{ item.createdAtForHumans }}</span>
                      </div>
                      <span class="update-task">{{ item.text }}</span>
                      <div class="attach-file" v-for="(doc, index) in item.documents" :key="index">
                        <div>
                          <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                          <span class="file-name">{{ doc.clientFilename }}</span>
                        </div>
                        <button @click="downloadDocument(doc.id, doc.clientFilename)">
                          <img src="@/assets/icons/icon-download.svg" alt="Delete">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Add new task -->
          <div class="add-new-task" v-show="tab === 'addTask'">
            <div class="modal-body-container create-task" @click="setTab('Notes')">
              <div class="d-flex align-items-center">
                <img src="@/assets/icons/icon-arrow-left.svg" alt="" class="me-2">
                <div class="modal-body-container__title">Create new task</div>
              </div>
            </div>
            <div class="modal-body-container border-0">
              <div class="form-group col-md-6 mt-3 full-width">
                <label for="fieldAddTask">Task Name</label>
                <input type="text" v-model="newTaskForm.title" id="fieldAddTask" class="form-control full-width">
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <label for="fieldAssigner">Assignee</label>
                  <multiselect v-model="newTaskForm.agent"
                               placeholder="Select Assigner"
                               track-by="id"
                               label="fullName"
                               id="fieldAssigner"
                               class="full-width"
                               :preselectFirst="true"
                               :options="newTaskForm.agents"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false"/>
                </div>
                <div class="col-md-6">
                  <label for="fieldFiles">File</label>
                  <multiselect v-model="newTaskForm.borrower"
                               placeholder="Select Assigner"
                               track-by="id"
                               label="name"
                               id="fieldFiles"
                               class="full-width"
                               :options="newTaskForm.borrowers"
                               :searchable="false"
                               :close-on-select="true"
                               :allow-empty="false"
                               :show-labels="false"/>
                </div>

              </div>
              <div class="form-group full-width mt-4 submit">
                <button class="btn btn-primary w-100" @click="addTask">
                  <span class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true" v-if="ui.saveLoading === true"></span>
                  <span v-if="ui.saveLoading === false">Create Task</span>
                </button>
              </div>
            </div>
          </div>
          <!-- End add new task -->

          <!-- Add new note -->
          <div class="form-group full-width" v-if="tab !== 'addTask'">
            <textarea class="form-control"
                      v-model="comment"
                      @keydown.prevent.ctrl.enter="addNote"
                      rows="4" aria-label="comment" placeholder="Ctrl + Enter - Add new note">
            </textarea>
            <button class="btn btn-primary mt-3" @click="addNote">
              <span class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"
                    v-if="ui.saveLoading">
              </span>
              <span v-if="!ui.saveLoading">Add Note</span>
            </button>
          </div>
          <!-- End add new note -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import PerfectScrollbar from 'perfect-scrollbar'
import vClickOutside from 'v-click-outside'
import UwmNotes from '../../loans/UwmNotes'

export default {
  name: 'NoteHeaderModal',
  directives: {clickOutside: vClickOutside.directive},
  components: {Multiselect, UwmNotes},
  data: () => ({
    borrower: {},
    borrowers: [],
    notes: [],
    tasks: [],
    attachments: [],
    tab: 'Notes',
    taskName: '',
    comment: '',
    newTaskForm: {
      title: '',
      agent: {},
      agents: [],
      borrower: {},
      borrowers: []
    },
    ui: {
      saveLoading: false,
      loading: false,
      ps: null
    }
  }),
  methods: {
    closeNote() {
      this.$emit('close');
    },
    setTab(tabName) {
      if (tabName === 'addTask') {
        this.getAgents()
      }
      this.tab = tabName;
    },
    getAgents() {
      this.$http.get('/api/v1/form/agents-active')
        .then((res) => this.newTaskForm.agents = res.data)
    },
    getBorrowers() {
      this.ui.loading = true;
      this.$http.get('/api/v1/form/loans')
        .then((res) => {
          this.borrowers = res.data
          this.borrower = this.borrowers[0]
          this.newTaskForm.borrower = this.borrower
          this.newTaskForm.borrowers = this.borrowers
          this.ui.loading = false

          if (this.borrower) {
            this.getNotes()
          }
        })
        .catch()
    },
    getNotes() {
      this.ui.loading = true;

      this.$http.get(`/api/v1/loans/notes/${this.borrower.id}`)
        .then((res) => {
          this.notes = res.data.notes
          this.notes.filter(note => {
            if (note.type === 'task') {
              note.task.commentsTotal = 0
              note.task.comments.filter(comment => {
                let commentsCount = comment.documents.length
                if (commentsCount > 0) {
                  note.task.commentsTotal += commentsCount
                  this.attachments.push(comment)
                }
              })

              this.tasks.push(note.task)
            }
            if (this.tab === 'addTask') {
              this.setTab('Todo')
            }
            this.ui.saveLoading = false
          })
          this.ui.loading = false
        })
        .catch()
    },
    downloadDocument(id, clientFilename) {
      this.$http.get(`/api/v1/task/comments/download/${id}`)
        .then(res => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', clientFilename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch()
    },
    addNote() {
      this.ui.saveLoading = true
      this.$http.post(`/api/v1/loans/notes/new/${this.borrower.id}`, {message: this.comment})
        .then((res) => {
          this.notes.unshift(res.data)
          this.comment = ''
          this.ui.saveLoading = false
        })
        .catch()
    },
    addTask() {
      this.ui.saveLoading = true
      this.$http.post('/api/v1/tasks/store', {
        title: this.newTaskForm.title,
        agentId: this.newTaskForm.agent.id,
        loanId: this.newTaskForm.borrower.id,
      })
        .then((res) => {
          this.tasks.unshift(res.data.task)
          this.getNotes()
          this.newTaskForm.title = ''
          this.newTaskForm.agent = {}
        })
        .catch()
    },
    updateBorrowerData(option) {
      this.borrower = option
      this.newTaskForm.borrower = this.borrower
      this.getNotes()
    }
  },
  beforeMount() {
    this.getBorrowers()
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar);
  }
}
</script>

<style lang="scss" scoped>
#headerNoteModal {
  @media (max-width: 568px) {
    transform: translate(-38%);
    width: max-content;
    right: initial;
    left: 0;
  }
  #modal-body-scroll {
    position: relative;
    height: 400px;
    margin-bottom: 20px;
  }

  textarea {
    &.form-control {
      border: 1px solid #dfdfdf;
      border-radius: 8px;
    }
  }

  .task-header-assigner {
    font-weight: 500;
  }

  .task-info-name,
  .task-header-assigner {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .task-date {
    opacity: 0.46;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 16px;
  }

  .assigned-task {
    white-space: break-spaces;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    letter-spacing: 0;
  }

  .task-body {
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
    padding: 9px;
    min-height: 60px;
    margin-top: 9px;
  }

  .task-comments {
    img {
      opacity: 0.46;
    }
    span {
      color: rgba(138, 138, 138, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
    }
  }

  .create-task {
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 20px;
  }

  .attach-file {
    img {
      width: 16px;
      height: 16px;
    }
  }

  .btn-link {
    font-size: 14px;
  }

  .form-group {
    .form-control {
      border: 1px solid #dfdfdf;
    }
  }

  .ps {
    height: 400px;
    overflow: hidden;
  }

  .app-updated {
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 4px;
    padding: 10px 12px;
    margin-top: 5px;

    img {
      height: 12px;
      width: 12px;
      margin-right: 5px;
    }

    div {
      width: 33%;

      &:first-child,
      &:last-child {
        color: rgba(0, 0, 0, 1);
        font-size: 11px;
        letter-spacing: 0;
        line-height: 17px;
      }

      &:nth-child(2) {
        span {
          opacity: 0.46;
          color: rgba(0, 0, 0, 1);
          font-size: 11px;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
  }

  .task-info {
    img {
      width: 18px;
      height: 18px;
    }
  }
  .avatar {
    height: 36px;
    width: 36px;
    border: 1px solid #ededee;
    border-radius: 50%;
    opacity: 1;
  }
}

::v-deep {
  .multiselect__single {
    color: #000000;
    font-weight: 500;
    font-size: 14px;
  }
}
</style>
