<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <Sidebar v-bind:showSideBar="showSidebar" />
        <main class="ms-sm-auto p-0" v-bind:class="{ 'col-lg-12 col-md-12': !showSidebar, 'col-lg-10 col-md-9': showSidebar }">
          <Header v-bind:showSideBar="showSidebar" @showSideBar="showSidebar = !showSidebar" />
          <div class="content">
            <AutoLogout />
            <vue-page-transition name="fade-in-left">
              <router-view />
            </vue-page-transition>
          </div>
        </main>
      </div>
    </div>

    <FilePreviewModal v-if="$store.state.filePreviewModal.isOpen"></FilePreviewModal>

    <TaskModal v-if="$store.getters.openTaskModal" />

    <transition name="fade">
      <status-alert-modal v-if="$store.state.statusNotification.isOpen" />
    </transition>

    <transition name="fade">
      <announcement-modal v-if="$store.state.announcement.isActive" />
    </transition>

    <transition name="fade">
      <end-user-license-agreement-modal v-if="$store.state.user.email && !$store.state.user.isAgreeToTerms" />
    </transition>

    <transition name="fade">
      <first-time-video-modal v-if="$store.state.user.isAgreeToTerms && $store.state.user.isFirstTime" />
    </transition>

    <transition name="fade">
      <flyer-modal v-if="$store.getters.getFlyerId"></flyer-modal>
    </transition>
  </div>
</template>

<script>
import Header from "./Header";
import Sidebar from "./Sidebar";
import FilePreviewModal from "./FilePreviewModal";
import TaskModal from "@/components/modal/TaskViewModal";
import StatusAlertModal from "./includes/StatusAlertModal";
import AutoLogout from "@/components/AutoLogout";
import AnnouncementModal from "@/components/modal/AnnouncementModal";
import FirstTimeVideoModal from "@/components/modal/FirstTimeVideoModal";
import EndUserLicenseAgreementModal from "@/components/modal/EndUserLicenseAgreementModal";
import FlyerModal from "@/components/modal/FlyerModal.vue";

export default {
  name: "Index",
  components: {
    FirstTimeVideoModal,
    AnnouncementModal,
    StatusAlertModal,
    FilePreviewModal,
    Header,
    Sidebar,
    TaskModal,
    AutoLogout,
    EndUserLicenseAgreementModal,
    FlyerModal
  },
  data: function() {
    return {
      showSidebar: true
    };
  }
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 100vh;
}
</style>
