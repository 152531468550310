<template>
  <div class="modal modal-center">
    <div class="modal-bg"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>User License Agreement</h3>
      </div>
      <div class="row h-100 w-100 m-auto">
        <div class="col-lg-12 p-0 h-100">
          <div class="ann">
            <div class="ann-body">
              <page-spinner class="mt-5 pt-5" v-if="ui.loading" />
              <div class="preview" v-else>
                <pdf :src="src" :page="1" class="page-preview" />
                <hr>
                <pdf :src="src" :page="2" class="page-preview" />
                <hr>
                <pdf :src="src" :page="3" class="page-preview" />
                <hr>
              </div>
              <div class="px-4 pb-3 d-flex justify-content-between flex-column flex-lg-row">
                <div class="form-check mt-3 w-100">
                  <input class="form-check-input mt-0" type="checkbox" v-model="isAgreed" id="isAgreeToTerms">
                  <label class="form-check-label" for="isAgreeToTerms">
                    I agree to the terms and conditions listed above
                  </label>
                </div>
                <button type="button" class="btn btn-primary mt-3 px-5" @click="closeModal" :disabled="!isAgreed">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import {getBackendUrl} from "@/utils/backendUrl";
import PageSpinner from '../pageSpinner.vue';

const loadingTask = pdf.createLoadingTask(getBackendUrl() + '/api/agent/eula');

export default {
  name: 'AnnouncementModal',
  components: {pdf, PageSpinner},
  data() {
    return {
      src: loadingTask,
      isAgreed: false,
      ui: {
        loading: false,
      }
    }
  },
  methods: {
    closeModal() {
      if (!this.isAgreed) { return; }
      let formData = new FormData();
      formData.append('isAgreed', this.isAgreed);
      this.$http.post(`/api/agent/agree-to-terms`, formData)
        .then(() => {
          this.$store.state.user.isAgreeToTerms = true;
        })
    }
  },
  mounted() {
    this.ui.loading = true;
    this.src.promise.finally(() => { this.ui.loading = false })
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.preview {
  width: 100%;
  height: 400px;
  overflow-y: auto;

}
.form-check-input { max-width: 20px !important; }
.modal {
  height: auto;
  &-block {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    margin: 100px auto!important;
    overflow-y: auto;
    @media (max-width: 768px) {
      width: 90%;
    }
    @media (min-width: 768px) {
      min-width: 700px;
      overflow: hidden;
    }
    .layout-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .ann {
      padding: 20px;
      @media (min-width: 768px) {
        padding: 0;
      }
      &-title {
        color: #051923;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        margin-bottom: 32px;
      }

      &-text {
        opacity: 0.7;
        color: #051923;
        font-family: "PT Serif";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        white-space: pre-line;
        margin: 0;
        height: 500px;
        overflow-y: auto;
        padding-bottom: 100px;
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          outline: 1px solid #9eacb6;
          border-radius: 4px;
        }
      }
    }
  }
  .modal-close {
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
.h-md-100 {
  @media (min-width: 768px) {
    height: 100%;
  }
}
</style>
