<template>
  <div id="feedbackModal" class="modal modal-center">
    <div class="modal-bg" @click="discard"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Submit Feedback</h3>
      </div>
      <div v-if="ui.loading" class="text-center mt-5 pt-5">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else class="modal-body">
        <span>
          If you found any bugs you can submit them here, paste screenshots if needed directly to the field below
        </span>
        <div class="row mt-3">
          <div class="col-md-12 mb-3">
            <ckeditor v-model="editorData" :config="editorConfig" :editor="editor"/>
          </div>
        </div>
      </div>
      <div v-if="!ui.loading" class="modal-footer d-flex justify-content-start">
        <button :disabled="ui.submit" class="btn btn-primary w-25" type="button">
          <span v-if="ui.submit" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
          <span v-else @click="submitFeedback">Submit</span>
        </button>
        <button class="btn btn-cancel px-5" type="reset" @click="discard">Discard</button>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import uploader from '@/utils/uploder';

export default {
  name: "FeedbackModal",
  data() {
    return {
      ui: {
        loading: false,
        submit: false
      },
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        extraPlugins: [
          this.MyUploadAdapterPlugin
        ]
      }
    }
  },
  methods: {
    MyUploadAdapterPlugin(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
        return new uploader(loader);
      };
    },
    submitFeedback() {
      this.ui.submit = true
      this.$http.post(`api/v1/feedback/new`, {content: this.editorData})
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.discard()
        })
        .catch((err) => this.alertError(err.response.data ? err.response.data : err.response.statusText))
        .finally(() => this.ui.submit = false)
    },
    discard() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
#feedbackModal {
  &.modal-center {
    .modal-block {
      width: 850px;
      min-height: 60vh;
      height: auto;
      margin-top: 7vh;
      border-radius: 8px;
    }

    .modal-body {
      height: 50vh;
      overflow: auto;
    }

    .modal-footer {
      position: absolute;
    }

    label {
      margin-bottom: .3rem;
    }

    input, textarea {
      background-color: #F3F3F4;
    }

    .ck.ck-editor__main > .ck-editor__editable {
      min-height: 200px !important;
      border-bottom-left-radius: 8px !important;
    }
  }
}
</style>
