<template>
  <div class="modal" id="statusAlert">
    <div class="notification-window">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <img src="@/assets/icons/icon-status-success.svg" alt="Success" v-if="status === 'success'">
          <img src="@/assets/icons/icon-info-white.svg" alt="Info" v-if="status === 'info'">
          <img src="@/assets/icons/icon-status-error.svg" alt="Error" v-if="status === 'error'">
          <span>
            {{ msg }}
          </span>
        </div>
        <img src="@/assets/icons/icon-remove white.svg" @click="closeModal" class="icon-close" alt="Close">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusAlertModal',
  data() {
    return {
      msg: this.$store.state.statusNotification.msg,
      status: this.$store.state.statusNotification.status,
      delay: this.$store.state.statusNotification.delay
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('CLOSE_STATUS_NOTIFICATION')
    }
  },
  created() {
    if (this.$store.state.statusNotification.delay !== 0) {
      setTimeout(() => {
        this.$store.dispatch('CLOSE_STATUS_NOTIFICATION')
      }, this.$store.state.statusNotification.delay)
    }
  }
}
</script>

<style lang="scss">
#statusAlert {
  .notification-window {
    width: fit-content;
    border-radius: 24px;
    background-color: #23232E;
    box-shadow: 2px 4px 42px 0 rgba(0,0,0,0.1);
    padding: 12px 12px 12px 18px;
    margin: 50px auto;
    img {
      margin-right: 12px;
    }
    span  {
      color: #FEFEFE;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      padding-left: 12px;
      border-left: 1px solid rgba(246, 246, 246, 0.1);
    }
    .icon-close {
      opacity: .46;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>
