<template>
  <div>
    <div class="modal modal-center">
      <div class="modal-bg"></div>
      <div class="modal-block">
        <div class="modal-close" @click="closeModal">
          <img src="@/assets/icons/icon-close-black.svg" alt="">
        </div>
        <div>
          <img :src="image" alt="" style="max-width: 100%;">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import BaseButton from '../../components/BaseButton.vue';
export default {
  name: 'FlyerModal',
  // components: { BaseButton },
  data() {
    return {
      dontShowAgain: false,
      image: '',
    }
  },
  methods: {
    closeModal() {
      console.log('test');
      this.$store.commit('setFlyerId', null);
    },
    showImage() {
      this.$http.get(`/api/settings/show-flyer/${this.$store.getters.getFlyerId}`, {
        responseType: 'blob'
      })
        .then((res) => {
          this.image = 'data:image/png;base64,'+ btoa(res.data)

          const urlCreator = window.URL || window.webkitURL
          this.image = urlCreator.createObjectURL(res.data)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.pushAlert('error', 'Unauthorized');
          }
        });
    }
  },
  mounted() {
    this.showImage();
  },
}
</script>

<style lang="scss" scoped>
.modal-close {
  position: absolute;
  top: -10px;
  right: -50px;
  padding: 10px;
  cursor: pointer;
}
.modal .modal-block, .modal .modal__block {
  overflow-y: initial!important;
  overflow-x: initial!important;
}
.modal-block {
  width: fit-content;
  max-width: 50%;
  height: initial!important;
  margin: 100px auto !important;
  overflow-y: initial!important;
  iframe {
    padding-bottom: 12px;
    height: 90% !important;
  }
  input {
    width: 18px!important;
    border-radius: 8px
  }
}
.modal-footer {
  position: absolute;
  bottom: 0;
  padding: 7.5px 10px;
}
</style>
