<template>
  <div>
    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
      <span>Main</span>
    </h6>
    <ul class="nav flex-column" v-if="!isGranted('ROLE_SOFT_DELETED')">
      <li class="nav-item"><!-- todo: app.user -->
        <router-link :to="{ name: 'dashboard' }" class="nav-link" :class="{ 'active': currentPage === 'dashboard' }">
          <img src="@/assets/icons/dashboard.svg" alt="Dashboard">
          Dashboard
        </router-link>
      </li>
      <li class="nav-item"><!-- todo: check condition -->
        <a :href="backendUrl('/mlo/account/agent')" class="nav-link">
          <img src="@/assets/icons/accounts.svg" alt="Accounts">
          Account
        </a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'loans' }" class="nav-link" :class="{ active: currentPage === 'loans' }">
          <img src="@/assets/icons/loans.svg" alt="Loans">
          Loans
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'lenders' }" class="nav-link" :class="{ active: currentPage === 'lenders' }">
          <img src="@/assets/icons/lenders.svg" alt="Lenders">
          Lenders
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'escrow_companies' }" class="nav-link" :class="{ active: currentPage === 'escrow_companies' }">
          <img src="@/assets/icons/escrow.svg" alt="Escrow Companies">
          Escrow Companies
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'funding_requests_pending' }" class="nav-link"
                     :class="{ active: ['funding_requests_pending', 'funding_requests_proceed', 'funding_requests_deleted'].includes(currentPage) }">
          <img src="@/assets/icons/funding-requests.svg" alt="Fundings">
          Funding Requests
          <div class="nav-notification bg-primary" v-if="counters.fundingRequests > 0">{{ counters.fundingRequests }}</div>
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_MLO') || isGranted('ROLE_CEO_ASSISTANT')">
        <router-link :to="{ name: 'quote_requests' }" class="nav-link" :class="{ 'active': currentPage === 'quote_requests' }">
          <img src="@/assets/icons/Icon-BlogArticle.svg" alt="quote requests">
          Quote Requests
        </router-link>
      </li>
      <li class="nav-item">
        <a :href="backendUrl('/mlo/expense/')" class="nav-link">
          <img src="@/assets/icons/Icon-Expenses.svg" alt="Expenses">
          Expenses
          <div class="nav-notification bg-primary" v-if="counters.expenses > 0">{{ counters.expenses }}</div>
        </a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'corr_pricing' }" class="nav-link" :class="{ active: currentPage === 'corr_pricing' }">
          <img src="@/assets/icons/Icon-Corr-Pricer.svg" alt="Corr Pricer">
          Corr Pricer
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'credit_auth' }" class="nav-link" :class="{ active: currentPage === 'credit_auth' }">
          <img src="@/assets/icons/Icon-credit-auth.svg" alt="Credit auth">
          Credit Auth
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'tasks' }" class="nav-link" :class="{ active: currentPage === 'tasks' }">
          <img src="@/assets/icons/Icon-Tasks.svg" alt="Tasks">
          Tasks
          <div class="nav-notification bg-primary" v-if="counters.tasksAssignedToMe > 0">{{ counters.tasksAssignedToMe }}</div>
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">
        <a class="nav-link" :href="backendUrl('/mlo/task/preset/')">
          <img src="@/assets/icons/Icon-TaskPresents.svg" alt="Task Presets">
          Task Presets
        </a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'retread' }" class="nav-link" :class="{ active: currentPage === 'retread' }">
          <img src="@/assets/icons/Icon-BlogArticle.svg" alt="retread">
          Retread
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'borrower_gift' }" class="nav-link" :class="{ active: currentPage === 'borrower_gift' }">
          <img src="@/assets/icons/Icon-Send-Gift.svg" alt="Borrower Gift">
          Borrower Gift
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT')">
        <a class="nav-link" :href="backendUrl('/mlo/gift/manage/')">
          <img src="@/assets/icons/Icon-Send-Gift.svg" alt="Gift Management">
          Gift Management
        </a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'rankings' }" :class="{ active: currentPage === 'rankings' }" class="nav-link">
          <img src="@/assets/icons/Icon-Rankings.svg" alt="Rankings">
          Rankings
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO_ASSISTANT')">
        <a class="nav-link" :href="backendUrl('/mlo/announcements/')">
          <img src="@/assets/icons/Icon-Announcements.svg" alt="Announcements">
          Announcements
        </a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'secure_inbox' }" :class="{ active: currentPage === 'secure_inbox' }" class="nav-link">
          <img src="@/assets/icons/Icon-SecureInbox.svg" alt="Secure Inbox">
          Secure Inbox
          <div class="nav-notification bg-primary" v-if="counters.receivedDocuments > 0">{{ counters.receivedDocuments }}</div>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'resource_center' }" class="nav-link" :class="{ active: currentPage === 'resource_center' }">
          <img src="@/assets/icons/icon-apps.svg" alt="Lenders">
          Resource Center
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'resolution_center' }" class="nav-link" :class="{ active: currentPage === 'resolution_center' }">
          <img src="@/assets/icons/Icon-Resource-Center.svg" alt="Resolution center">
          Resolution Center
          <div class="nav-notification bg-primary" v-if="counters.tickets > 0">{{ counters.tickets }}</div>
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CLOSER')">
        <a class="nav-link" :href="backendUrl('/mlo/loan-pdf/')">
          <img src="@/assets/icons/Icon-ClosingDownloadBot.svg" alt="Closing Download Bot">
          Closing Download Bot
        </a>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_PROCESSOR') || isGranted('ROLE_MLO')">
        <router-link :to="{ name: 'touch_screen' }" class="nav-link" :class="{ active: currentPage === 'touch_screen' }">
          <img src="@/assets/icons/Icon-TouchFile.svg" alt="Touch Screen">
          Touch Screen
          <div class="nav-notification bg-success" v-if="counters.loansTouched > 0">{{ counters.loansTouched }}</div>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'mailer_approval_form' }" class="nav-link" :class="{ active: currentPage === 'mailer_approval_form' }">
          <img src="@/assets/icons/lenders.svg" alt="Lenders">
          Compliance Review
        </router-link>
      </li>
      <li class="nav-item" v-if="isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_MLO')">
        <router-link :to="{ name: 'withdrawal' }" class="nav-link" :class="{ active: currentPage === 'withdrawal' }">
          <img src="@/assets/icons/Icon-WithdrawalRequests.svg" alt="Withdrawal Requests">
          Withdrawal Requests
          <div class="nav-notification bg-primary" v-if="counters.withdrawalRequests > 0">
            {{ counters.withdrawalRequests }}
          </div>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'w2_commissions' }" :class="{ active: currentPage === 'w2_commissions' }" class="nav-link">
          <img src="@/assets/icons/Icon-Expenses.svg" alt="Rankings">
          W2 Commissions
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'team_directory' }" :class="{ active: currentPage === 'team_directory' }" class="nav-link">
          <img src="@/assets/icons/Icon-Team Directory.svg" alt="Rankings">
          Company Directory
        </router-link>
      </li>
    </ul>
    <ul class="nav flex-column" v-else>
      <li class="nav-item">
        <router-link :to="{ name: 'funding_requests_pending' }" class="nav-link"
                     :class="{ active: ['funding_requests_pending', 'funding_requests_proceed', 'funding_requests_deleted'].includes(currentPage) }">
          <img src="@/assets/icons/funding-requests.svg" alt="Fundings">
          Funding Requests
          <div class="nav-notification bg-primary" v-if="counters.fundingRequests > 0">{{ counters.fundingRequests }}</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MainLinksList',
  props: { counters: Object },
  computed: {
    currentPage() {
      return this.$route.name
    }
  },
}
</script>
