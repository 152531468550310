<template>
  <ul class="dropdown-menu" id="user-menu-dropdown" :class="{ show : showDropdown }" @click="$emit('toggleDropdown')">
    <li class="d-flex align-items-center dropdown-item" v-if="isGranted('ROLE_SOFT_DELETED') === false">
      <router-link :to="{ name: 'users_details', params: {id: $store.getters.getUserId} }" class="router-link">
        <img src="@/assets/icons/icon-profile.svg" alt="profile" class="me-2">
        My Profile
      </router-link>
    </li>
    <li class="d-flex align-items-center dropdown-item"
        @click="$emit('toggleFeedbackModal')"
        v-if="isGranted('ROLE_SOFT_DELETED') === false">
      <img src="@/assets/icons/icon-submit-feedback.svg" alt="profile" class="me-2">
      Submit feedback
    </li>
    <li class="d-flex align-items-center dropdown-item"
        v-if="isGranted('ROLE_SOFT_DELETED') === false"
        @click="goto(backendUrl(`/mlo/user/${$store.getters.getUserId}/password`))">
      <img src="@/assets/icons/icon-change-password.svg" alt="profile" class="me-2">
      Change Password
    </li>
    <li class="d-flex align-items-center dropdown-item" @click="logout">
      <img src="@/assets/icons/icon-signout.svg" alt="profile" class="me-2">
      Sign Out
    </li>
  </ul>
</template>

<script>
export default {
  name: "UserMenuModal",
  props: {
    showDropdown: Boolean
  },
  methods: {
    logout() {
      this.$http.get(`/api/v1/auth/logout/${this.$store.getters.getUserId}`)
      .finally(() => {
          this.$store.dispatch("LOGOUT");
          location.reload();
      })
      .catch((err) => {
        throw new Error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

#user-menu-dropdown {
  @media (max-width: 568px) {
    width: max-content;
    left: 0;
    transform: translateX(-70%);
  }
}

.dropdown-item {
  .router-link{
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
  }
}
</style>
