<template>
  <div>
    <div class="modal modal-center">
      <div class="modal-bg"></div>
      <div class="modal-block">
        <div>
          <iframe src="https://player.vimeo.com/video/794528837?h=f3d541dbbd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;" title="Ops training"></iframe>
        </div>
        <div class="modal-footer justify-content-between align-items-center">

          <div class="form-check p-0">
            <input type="checkbox"
                   :value="dontShowAgain"
                   v-model="dontShowAgain"
                   id="dontShowAgain"
                   name="dontShowAgain" />
            <label class="form-check-label" for="dontShowAgain">Don't show again</label>
          </div>

          <base-button title="Close" action="secondary-default" size="sm" @click-btn="closeModal" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BaseButton from '../../components/BaseButton.vue';
export default {
  name: 'FirstTimeVideoModal',
  components: { BaseButton },
  data() {
    return {
      dontShowAgain: false
    }
  },
  methods: {
    closeModal() {
      this.$http.get('/api/v1/user/toggle-first-login')
        .then(() => {
          this.$store.dispatch('UPDATE_IS_FIRST_TIME');
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.pushAlert('error', 'Unauthorized');
          }
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-block {
  height: initial!important;
  min-height: 500px!important;
  margin: 100px auto !important;
  iframe {
    padding-bottom: 12px;
    height: 90% !important;
  }
  input {
    width: 18px!important;
    border-radius: 8px
  }
}
.modal-footer {
  position: absolute;
  bottom: 0;
  padding: 7.5px 10px;
}
</style>
