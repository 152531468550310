<template>
  <div class="modal modal-center" v-if="warningZone">
    <div class="modal-bg"></div>
    <div class="modal-block">
      <div class="modal-body warning">
        <img class="warning-img" src="@/assets/icons/icon-warning.svg" />
        <div class="warning-message">
          <h2>Your session is about to expire!</h2>
          <p class="text-muted">
            You will be automatically logged out in
            <strong>{{ timer }}</strong> secounds.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AutoLogout",
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      timer: 59,
      warningTimer: null,
      logoutTimer: null,
      warningZone: false
    };
  },
  mounted() {
    this.events.forEach(function(event) {
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.setTimers();
  },
  destroyed() {
    this.events.forEach(function(event) {
      window.removeEventListener(event, this.resetTimer);
    }, this);

    this.resetTimer();
  },
  methods: {
    setTimers() {
      this.warningTimer = setTimeout(this.warningMessage, 59 * 60 * 1000); // 09 minutes - 9 * 60 * 1000
      this.logoutTimer = setTimeout(this.logoutUser, 60 * 60 * 1000); // 60 minutes - 10 * 60 * 1000

      this.warningZone = false;
    },
    warningMessage() {
      this.timer = 59;
      this.warningZone = true;
      this.countDownTimer();
    },
    logoutUser() {
      this.$http.get(`/api/v1/auth/logout/${this.$store.getters.getUserId}`)
      .finally(() => {
          this.$store.dispatch("LOGOUT");
          window.location.reload();
      })
      .catch((err) => {
        throw new Error(err)
      })
    },
    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);

      this.setTimers();
    },
    countDownTimer() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-block {
  height: auto !important;
  border-radius: 8px !important;
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .warning {
    padding: 2rem !important;
    display: flex;

    &-img {
      width: 130px;
    }

    &-message {
      text-align: center;
      color: #4c4c4c;
      margin: auto;
    }
  }
}
</style>
