<template>
  <div>
    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
      <span>Main</span>
    </h6>
    <ul class="nav flex-column">
      <li class="nav-item">
        <router-link :to="{ name: 'funding_requests_pending' }" class="nav-link"
                     :class="{ active: ['funding_requests_pending', 'funding_requests_proceed', 'funding_requests_deleted'].includes(currentPage) }">
          <img src="@/assets/icons/funding-requests.svg" alt="Fundings">
          Funding Requests
          <div class="nav-notification bg-primary" v-if="counters.fundingRequests > 0">{{ counters.fundingRequests }}</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MainLinksList',
  props: { counters: Object },
  computed: {
    currentPage() {
      return this.$route.name
    }
  },
}
</script>
