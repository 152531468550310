<template>
  <transition name="slide-fade">
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 sidebar" v-show="showSideBar">
      <div class="sidebar-header">
        <div class="sidebar-header__toggle" :class="{active: showAppDropdown}" @click="toggleAppDropdown">
          <img src="@/assets/images/logo-vision.svg" class="logo" alt="vision">
          <img src="@/assets/icons/bold-icon checkbox-dropdown (1).svg" alt="">
        </div>

        <transition name="fade">
          <div class="sidebar__dropdown" v-if="showAppDropdown" v-click-outside="toggleAppDropdown">
            <span>Apps</span>
            <a :href="crmUrl">
              <img src="@/assets/images/logo-cmc vision colored (1).svg" alt="CRM Leads">
            </a>
            <a :href="`${baseUrl}/?email=${$store.getters.getEmail}`" target="_blank" class="cursor-pointer sidebar__dropdown-tab">
              <img src="@/assets/images/logo-cmc vision colored (1).svg" alt="CRM Leads">2.0
            </a>
          </div>
        </transition>
      </div>
      <div id="navbar-perfect-scrollbar" class="sidebar-nav">
        <!--    Link list for main section    -->
        <soft-deleted-links :counters="counters" v-if="isGranted('ROLE_SOFT_DELETED')"/>

        <!--    Link list for main section    -->
        <main-links-list :counters="counters" v-if="isGranted('ROLE_SOFT_DELETED') === false"/>

        <!--    Links list for admin section    -->
        <admin-links-list :counters="counters" v-if="isGranted('ROLE_SOFT_DELETED') === false"/>

        <!--    Other links    -->
        <ul class="nav flex-column" v-if="isGranted('ROLE_SOFT_DELETED') === false">
          <template v-if="isGranted('ROLE_BOOKKEEPER')">
            <li class="nav-label">
              <label class="content-label">
                BOOKKEEPER
              </label>
            </li>
            <li class="nav-item">
              <a :href="backendUrl('/mlo/expense/')" class="nav-link">
                <img src="@/assets/icons/Icon-Expenses.svg" alt="Expenses">
                Expenses
                <div class="nav-notification bg-primary" v-if="counters.expenses > 0">{{ counters.expenses }}</div>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="backendUrl('/mlo/vendor/')">
                <img src="@/assets/icons/Icon-ExpensesVendors.svg" alt="Vendors Expenses">
                Vendors Expenses
              </a>
            </li>
          </template>
          <template v-if="isGranted('ROLE_PROCESSOR')">
            <li class="nav-label">
              <label class="content-label">
                PROCESSOR
              </label>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'loans' }" class="nav-link" :class="{ active: currentPage === 'loans' }">
                <img src="@/assets/icons/loans.svg" alt="Loans">
                Loans
                <div class="nav-notification bg-primary" v-if="counters.loans > 0">{{ counters.loans }}</div>
              </router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="backendUrl('/mlo/lender/')">
                <img src="@/assets/icons/lenders.svg" alt="Lenders">
                Lenders
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="backendUrl('/mlo/app')">
                <img src="@/assets/icons/Icon-AppsSidebar.svg" alt="Apps">
                Apps
              </a>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'resolution_center' }" class="nav-link"
                           :class="{ active: currentPage === 'resolution_center' }">
                <img src="@/assets/icons/Icon-Resource-Center.svg" alt="Resolution center">
                Resolution Center
                <div class="nav-notification bg-primary" v-if="counters.tickets > 0">{{ counters.tickets }}</div>
              </router-link>
            </li>
          </template>
        </ul>
        <div class="nav-link" v-if="isGranted('ROLE_SOFT_DELETED') === false">
          <div class="form-check form-switch w-100">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked @change="switchDesign">
            <label class="form-check-label" for="flexSwitchCheckChecked">New Design</label>
          </div>
        </div>
      </div>
    </nav>
  </transition>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import MainLinksList from "./includes/sidebar/MainLinksList";
import AdminLinksList from "./includes/sidebar/AdminLinksList";
import SoftDeletedLinks from "./includes/sidebar/SoftDeletedLinks";

export default {
  name: "Sidebar",
  components: {AdminLinksList, MainLinksList, SoftDeletedLinks},
  props: {
    showSideBar: Boolean
  },
  data() {
    return {
      counters: this.getCounters(),
      showAppDropdown: false,
      loginUrl: null,
      baseUrl: 'https://app.clearmortgagecapital.com'
    }
  },
  computed: {
    currentPage() {
      return this.$route.name
    },
    crmUrl() {
      return this.getCrmUrl()
    }
  },
  methods: {
    switchDesign() {
      this.$http.post('/api/v1/design/old').then(() => {
        this.goto(this.backendUrl('/mlo/loans'))
      }).catch()
    },
    toggleAppDropdown() {
      this.showAppDropdown = !this.showAppDropdown;
    },
    loginUser() {
      this.$http.get(`${this.baseUrl}/hooks/v1/get-token?email=${this.$store.getters.getEmail}`)
        .then((res) => {
          window.open(`${this.baseUrl}/login-user?token=${res.data.token}`, '_blank')
        })
        .catch(() => {
          this.pushAlert('danger', 'Something went wrong. Please try again later.')
        })
    },
  },
  mounted() {
    const scrollbar = document.getElementById('navbar-perfect-scrollbar');
    new PerfectScrollbar(scrollbar)

    this.loginUrl = `https://app.clearmortgagecapital.com/hooks/v1/login?email=${this.$store.getters.getEmail}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9`
  }
}
</script>

<style lang="scss" scoped>
.sidebar-nav {
  position: relative;
  padding: 20px 10px;
  overflow: hidden;
  height: calc(100% - 72px);
}

.sidebar-header {
  position: relative;
  padding: 0!important;
  .logo {
    display: block;
    margin-right: 19px;
  }
  &__toggle {
    display: flex;
    align-items: center;
    padding: 14px 29px;
    transition: all .3s;
    cursor: pointer;
    &.active {
      background-color: rgba(0,0,0, .05);
    }
    &:hover {
      background-color: rgba(0,0,0, .05);
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    height: 60%;
    width: 1px;
    border: 1px solid #8a8a8a;
    opacity: .4;
    transform: translateY(-50%);
  }
}

.sidebar__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #ffffff;
  z-index: 999;
  border: 1px solid #D0D0D0;
  border-radius: 0 0 4px 4px;
  background-color: #FFFFFF;

  span {
    display: block;
    padding: 8px 29px;
    color: rgba(0, 0, 0, .6);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  a, &-tab {
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px 29px;
    transition: all .3s;
    cursor: pointer;
    &:hover {
      background-color: rgba(0,0,0, .05);
    }
  }
}
</style>
