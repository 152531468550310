<template>
  <header class="navbar navbar-dark sticky-top flex-md-nowrap ps-3 pe-3">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarMenu"
            aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <a href="#" class="burger-menu" @click="onClickButton">
        <img src="@/assets/icons/icon-menu.svg" alt="Burger Menu">
      </a>
    </button>
    <div class="d-flex align-items-center dropdown-sub-menu">
      <div class="dropdown-account d-flex align-items-center me-2">
        <div class="dropdown" v-click-outside="closePurchaseMenu">
          <div class="d-flex align-items-center" @click="activeTab = 'purchase'">
            <div class="user-info">
              <div class="user-name">Purchase Hub</div>
            </div>
            <img src="@/assets/icons/icon-drop-down-expand.svg" class="dropdown-arrow" alt="dropdown image">
          </div>
          <ul class="dropdown-menu" id="user-menu-dropdown" :class="{ show : activeTab === 'purchase' }" @click="closePurchaseMenu">
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('pre_approval_letter_create')">Create Pre-Approval</div>
            </li>
            <li class="d-flex align-items-center dropdown-item" >
              <div @click="pushTo('pre_approval_letter_create')">Request Pre-Approval Stips</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo(null, null, '/mlo/quote')">Create Presentation</div>
            </li>
            <li class="d-flex align-items-center dropdown-item" >
              <div @click="pushTo('house_flyer_editor')">Create Open House Flyer</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('homebuyer_guide')">Create Home Buyer Guide</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="dropdown-account d-flex align-items-center me-2">
        <div class="dropdown" v-click-outside="closeMarketingMenu">
          <div class="d-flex align-items-center" @click="activeTab = 'marketing'">
            <div class="user-info">
              <div class="user-name">Marketing Hub</div>
            </div>
            <img src="@/assets/icons/icon-drop-down-expand.svg" class="dropdown-arrow" alt="dropdown image">
          </div>
          <ul class="dropdown-menu" id="user-menu-dropdown" :class="{ show : activeTab === 'marketing' }" @click="closeMarketingMenu">
            <li class="d-flex align-items-center dropdown-item">
              <a href="https://www.jotform.com/form/241074947106052" target="_blank">Create Marketing Plan</a>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('business_card_generator')">Create Business Cards</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('ad_wizard')">Create Magazine Advertisement</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('assets_creation')">Request Custom Graphic Design</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('assets_creation')">Request Custom Flyer</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('assets_creation')">Request Custom Social Tile</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <router-link :to="{ path: '/resource-center/marketing-resource#', hash: 'lead' }">View/Contact Lead Providers</router-link>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('mailer_approval_form')">Request Compliance Review</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('brands')">Logos and Branding</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('marketing_resource')">Other Resources</div>
            </li>
            <li class="d-flex align-items-center dropdown-item" >
              <div @click="pushTo('house_flyer_editor')">Create Open House Flyer</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="dropdown-account d-flex align-items-center me-2">
        <div class="dropdown" v-click-outside="closeHRMenu">
          <div class="d-flex align-items-center" @click="activeTab = 'hrhub'">
            <div class="user-info">
              <div class="user-name">HR Hub</div>
            </div>
            <img src="@/assets/icons/icon-drop-down-expand.svg" class="dropdown-arrow" alt="dropdown image">
          </div>
          <ul class="dropdown-menu" id="user-menu-dropdown" :class="{ show : activeTab === 'hrhub' }" @click="closeHRMenu">
            <li class="d-flex align-items-center dropdown-item">
              <router-link :to="{ path: '/resolution-center', hash: 'hr' }">Create HR Ticket</router-link>
            </li>
            <li class="d-flex align-items-center dropdown-item" >
              <div @click="pushTo('withdrawal')">Withdrawal Funds</div>
            </li>
            <li class="d-flex align-items-center dropdown-item disabled">
              <a :href="backendUrl('/mlo/agreement/')" class="disabled">
                Request Onboarding
              </a>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('submit_confidential_feedback')">Submit Confidential Feedback</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="dropdown-account d-flex align-items-center me-2">
        <div class="dropdown" v-click-outside="closeSupportMenu">
          <div class="d-flex align-items-center" @click="activeTab = 'support'">
            <div class="user-info">
              <div class="user-name">Support Hub</div>
            </div>
            <img src="@/assets/icons/icon-drop-down-expand.svg" class="dropdown-arrow" alt="dropdown image">
          </div>
          <ul class="dropdown-menu" id="user-menu-dropdown" :class="{ show : activeTab === 'support' }" @click="closeSupportMenu">
            <li class="d-flex align-items-center dropdown-item">
              <router-link :to="{ path: '/resolution-center', hash: 'it' }">Create IT ticket</router-link>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <a href="mailto:fraud@clearmortgagecapital.com">Fraud Report</a>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <div @click="pushTo('mailer_approval_form')">Request Compliance Review</div>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <a href="mailto:support@lendingpad.com">LendingPad Support</a>
            </li>
            <li class="d-flex align-items-center dropdown-item" >
              <a href="https://www.advcredit.com/systems-support/" target="_blank">Advantage Credit Support</a>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <a href="https://www.smartlender.ai/" target="_blank">SmartLender Home Page</a>
            </li>
            <li class="d-flex align-items-center dropdown-item">
              <a href="https://bit.ly/LendingPadTraining" target="_blank">LendingPad Training Video</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="header-right-menu d-flex">
      <div class="dropdown-btn d-flex align-items-center justify-content-center position-relative"
           @click="modal.timeTracker = true" v-if="isGranted('ROLE_SOFT_DELETED') === false">
        <img src="@/assets/icons/icon-Clock.svg" alt="Timer">
        <transition name="fade">
          <time-tracker-modal v-if="modal.timeTracker === true" @close="modal.timeTracker = false"/>
        </transition>
      </div>
      <div class="dropdown-btn d-flex align-items-center justify-content-center position-relative"
           @click="modal.showNoteModal = true" v-if="isGranted('ROLE_SOFT_DELETED') === false">
        <img src="@/assets/icons/icon-Add-Note.svg" alt="Notes">
        <transition name="fade">
          <note-header-modal v-if="modal.showNoteModal === true" @close="modal.showNoteModal = false"/>
        </transition>
      </div>
      <div class="dropdown-btn d-flex align-items-center justify-content-center position-relative"
           @click="modal.showNotificationModal = true" v-if="isGranted('ROLE_SOFT_DELETED') === false">
        <img src="@/assets/icons/icon-notification.svg" alt="Notifications">
        <transition name="fade">
          <notification-modal v-if="modal.showNotificationModal === true" @close="modal.showNotificationModal = false"/>
        </transition>
      </div>
      <div class="dropdown-account d-flex align-items-center">
        <div class="dropdown" v-click-outside="clickOutsideToggle">
          <div class="d-flex align-items-center" @click="toggleUserMenu">
            <div class="user-avatar-block position-relative">
              <div class="avatar">
                <v-avatar :src="agentPhoto($store.getters.getPhoto)" :size="36" rounded />
              </div>
              <div class="user-active"></div>
            </div>
            <div class="user-info">
              <div class="user-name">{{ $store.getters.getFullName }}</div>
              <div class="user-status">{{ $store.getters.getPosition }}</div>
            </div>
            <img src="@/assets/icons/icon-drop-down-expand.svg" class="dropdown-arrow" alt="dropdown image">
          </div>
          <UserMenuModal
            :showDropdown="modal.showUserDropdown"
            @toggleDropdown="toggleUserMenu"
            @toggleFeedbackModal="toggleFeedbackModal"
          />
        </div>
      </div>
    </div>
    <transition name="fade">
      <FeedbackModal v-if="modal.showFeedbackModal" @close="toggleFeedbackModal"/>
    </transition>
  </header>
</template>

<script>
import NoteHeaderModal from "./includes/NoteHeaderModal";
import NotificationModal from "./includes/NotificationModal";
import UserMenuModal from "./includes/UserMenuModal";
import FeedbackModal from './includes/FeedbackModal'
import TimeTrackerModal from "./includes/TimeTrackedModal";
import vClickOutside from 'v-click-outside'

export default {
  name: "Header",
  components: {TimeTrackerModal, NotificationModal, NoteHeaderModal, UserMenuModal, FeedbackModal},
  directives: {clickOutside: vClickOutside.directive},
  props: {
    showSideBar: Boolean
  },
  data() {
    return {
      modal: {
        showNoteModal: false,
        showNotificationModal: false,
        showAppDrawerModal: false,
        showUserDropdown: false,
        timeTracker: false,
        showFeedbackModal: false
      },
      activeTab: null
    }
  },
  methods: {
    onClickButton() {
      this.$emit('showSideBar')
    },
    toggleUserMenu() {
      this.modal.showUserDropdown = !this.modal.showUserDropdown
    },
    clickOutsideToggle() {
      this.modal.showUserDropdown = false
    },
    toggleFeedbackModal() {
      this.modal.showFeedbackModal = !this.modal.showFeedbackModal
    },
    closePurchaseMenu() {
      if (this.activeTab === 'purchase') {
        this.activeTab = null
      }
    },
    closeMarketingMenu() {
      if (this.activeTab === 'marketing') {
        this.activeTab = null
      }
    },
    closeHRMenu () {
      if (this.activeTab === 'hrhub') {
        this.activeTab = null
      }
    },
    closeSupportMenu() {
      if (this.activeTab === 'support') {
        this.activeTab = null
      }
    },
    sendEmailRequest() {
      this.$http
        .get(`/api/v1/send-marketing-plan-request`)
        .then(() => {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'We have received your request to create a marketing plan.  A marketing expert will contact you shortly.',
              status: 'success',
              delay: 2000
            })
        })
        .catch(() => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Error',
            status: 'error',
            delay: 2000
          });
        });
    },
    pushTo(routeName, params = {}, oldLinkUrl = '') {
      // this.closeHubMenu()
      oldLinkUrl
        ? window.open(this.backendUrl(oldLinkUrl), '_blank')
        : this.$router.push({name: routeName, params: params})
    }
  }
}
</script>

<style lang="scss">
.dropdown-note {
  bottom: 0;
  top: 40px;
  right: 0;
  height: 696px;
  width: 472px;
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  cursor: initial;
  .select-borrower {
    max-width: 100%;

    .multiselect__tags {
      border-width: 0 0 1px 0;
      border-bottom: 1px solid rgba(239, 239, 239, 1);
      border-radius: 0;
    }

    .multiselect__option {
      transition: all .3s;

      &:hover {
        background: #f3f3f4;
        color: #23232e;
      }
    }

    .multiselect__option--highlight {
      background: none;
      text-align: left;
      padding: .8em 1em;
      color: rgba(35, 35, 46, .83);
      cursor: pointer;
      border-top: 1px solid rgba(0, 0, 0, .05);
      font-size: 14px;

      &:after {
        display: none;
      }
    }
  }
}

header {
  border-bottom: 1px solid #E7E8E8;
  background: white;
}

.dropdown-account {
  transition: all .3s;
  cursor: pointer;

  .avatar {
    margin-right: 10px;
    transition: all .3s;
  }

  .user-name {
    color: #000;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }

  .user-status {
    color: grey;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .user-info {
    @media screen and (max-width: 568px) {
      display: none;
    }
  }

  .dropdown-arrow {
    width: 24px;
    height: 24px;
  }

  .dropdown-menu {
    top: calc(100% + 15px);
    min-width: 12rem;

    li {
      font-size: 12px;

      &:active {
        color: #16181b;
        background-color: #f8f9fa;
      }
    }
  }
}

.user-active {
  position: absolute;
  bottom: -2px;
  right: 7px;
  height: 14px;
  width: 14px;
  border: 2px solid #F6F6F6;
  background-color: #00A933;
  border-radius: 50%;
  margin: 0;
}
.dropdown-sub-menu {

  .dropdown-item {
    padding: 0;
    a {
      font-weight: 400;
      color: #212529;
      text-decoration: none;
      width: 100%;
      padding: 0.25rem 1rem;
    }

    div {
      font-weight: 400;
      color: #212529;
      text-decoration: none;
      width: 100%;
      padding: 0.25rem 1rem;
    }
  }
}
.disabled {
  opacity: .7;
  cursor: none;
  pointer-events: none;
}
</style>
