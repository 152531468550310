<template>
  <div class="modal modal-center">
    <div class="modal-bg"></div>
    <div class="modal-block">
      <img src="@/assets/icons/icon-close.svg" alt="" class="modal-close" @click="closeModal">
      <div class="row h-100 w-100 m-auto">
        <div class="col-lg-5 p-0 h-md-100">
          <img src="@/assets/images/Announcement.svg" alt="" class="layout-img">
        </div>
        <div class="col-lg-7 p-0 h-100">
          <div class="ann">
            <div class="ann-body">
              <div class="ann-title">
                Announcement
              </div>
              <div class="ann-text" v-html="$store.state.announcement.message"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnnouncementModal',
  methods: {
    closeModal() {
      this.$http.get(`/api/v1/announcement/mark-as-read/${this.$store.state.announcement.id}`)
        .then(() => {
          this.$store.dispatch('CLOSE_ANNOUNCEMENT_MODAL')
        });
    }
  }

}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.modal {
  &-block {
    position: relative;
    max-height: 600px;
    border-radius: 16px;
    overflow: hidden;
    margin: 100px auto!important;
    overflow-y: auto;
    @media (max-width: 768px) {
      width: 90%;
    }
    @media (min-width: 768px) {
      min-width: 996px;
      overflow: hidden;
    }
    .layout-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .ann {
      padding: 20px;
      @media (min-width: 768px) {
        padding: 60px;
      }
      &-title {
        color: #051923;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        margin-bottom: 32px;
      }

      &-text {
        opacity: 0.7;
        color: #051923;
        font-family: "PT Serif";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        white-space: pre-line;
        margin: 0;
        height: 500px;
        overflow-y: auto;
        padding-bottom: 100px;
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          outline: 1px solid #9eacb6;
          border-radius: 4px;
        }
      }
    }
  }
  .modal-close {
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
.h-md-100 {
  @media (min-width: 768px) {
    height: 100%;
  }
}
</style>
