<template>
  <div>
    <div class="position-absolute dropdown-note" id="header-notification"
         :class="{'emptyNotification': notifications.length === 0}" v-click-outside="closeNotification">
      <div class="text-center" :class="[ ( notifications.length === 0 ) ? 'mt-2' : 'mt-5' ]" v-if="ui.loading === true">
        <div class="spinner-border mt-2 mb-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div class="dropdown-note-header">
          <h2>Notification</h2>
          <img src="@/assets/icons/icon-allread-icon-mark as read.svg"
               @click="markAllAsRead"
               content="Mark all as read"
               v-tippy="{arrow: true, placement: 'left-center'}"
               v-show="notifications.length"
               alt="As Read">
        </div>
        <div class="modal-body p-0" id="modal-body-scroll">
          <div class="row w-100 m-auto">
            <div class="col-12 notification" v-for="(notification, index) in notifications" :key="index">
              <div class="row w-100">
                <div class="col-1">
                  <div class="withdrawal-request">
                    <img src="@/assets/icons/Icon-Expenses.svg" alt="Expense"
                         v-if="notification.type === 'NewExpenseNotification'">
                    <img src="@/assets/icons/Icon-Withdrawal Requests.svg" alt="Withdrawal"
                         v-else-if="notification.type === 'NewWithdrawalNotification'">
                    <img src="@/assets/icons/icon-submit-feedback.svg" alt="Feedback"
                         v-else-if="notification.type === 'NewFeedbackNotification'">
                    <img src="@/assets/icons/icon-submit-feedback.svg" alt="Notification" v-else>
                  </div>
                </div>
                <div class="col-11 ps-3">
                  <div class="notification-title" v-if="notification.type === 'NewExpenseNotification'">
                    {{ notification.data.agent }} added new expense for amount of ${{ notification.data.amount }} to
                    your
                    account, please review it.
                  </div>
                  <div class="notification-title" v-else-if="notification.type === 'NewWithdrawalNotification'">
                    {{ notification.data.agent }} submitted new withdrawal request for amount of
                    ${{ notification.data.amount }}, please process it.
                  </div>
                  <div class="notification-title" v-else-if="notification.type === 'NewFeedbackNotification'">
                    {{ notification.data.agent }} submitted new feedback, click here to open it.
                  </div>
                  <div class="notification-title" v-else>
                    {{ notification.message }}
                  </div>
                  <div class="notification-date">{{ notification.createdAt }}</div>
                </div>
              </div>
            </div>
            <div class="col-12 notification" v-if="notifications.length === 0">
              <div class="row w-100">
                <div class="col-1">
                  <div class="non-notification">
                    <img src="@/assets/icons/icon-great.svg" alt="Great">
                  </div>
                </div>
                <div class="col-11 ps-3">
                  <div class="notification-title">
                    Great job!
                  </div>
                  <div class="notification-date mt-1">There's no new notifications.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar'
import vClickOutside from 'v-click-outside'

export default {
  name: 'NotificationModal',
  directives: {clickOutside: vClickOutside.directive},
  data() {
    return {
      ui: {
        loading: false
      },
      notifications: []
    }
  },
  methods: {
    getNotifications() {
      this.ui.loading = true;
      this.$http.get('/api/v1/notifications')
        .then((res) => {
          this.notifications = res.data
          this.ui.loading = false;
        })
    },
    markAllAsRead() {
      this.$http.get('/api/v1/notifications/read')
        .then(() => {
          this.notifications = []
        })
        .catch()
    },
    closeNotification() {
      this.$emit('close');
    }
  },
  beforeMount() {
    this.getNotifications();
  },
  mounted() {
    setTimeout(() => {
      const scrollbar = document.getElementById('modal-body-scroll');
      new PerfectScrollbar(scrollbar)
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>

#header-notification {
  @media (max-width: 568px) {
    width: max-content;
    left: 0;
    transform: translateX(-40%);
  }
}

#modal-body-scroll {
  position: relative;
  padding: 20px 10px;
  overflow: hidden;
  height: calc(100% - 50px);
}
.emptyNotification {
  height: auto !important;
  min-height: 115px;
}
.dropdown-note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
  border-bottom: 1px solid rgba(239, 239, 239, 1);

  h2 {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
  }
}

.notification {
  transition: all .3s;
  padding: 12px 18px;

  &:hover {
    background-color: rgba(38, 111, 229, .05);
  }

  &-title {
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 21px;
  }

  &-date {
    opacity: 0.5;
    color: rgba(0, 0, 0, 1);
    font-size: 11px;
    letter-spacing: 0;
    line-height: 12px;
    margin-top: 9px;
  }
}

.non-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
}

.withdrawal-request,
.account-request,
.rankings-request {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.withdrawal-request {
  background-color: rgba(0, 194, 223, .1);
}

.account-request {
  background-color: rgba(0, 215, 104, .1);
}

.rankings-request {
  background-color: rgba(255, 109, 0, .1);
}
</style>
