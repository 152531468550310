<template>
  <div class="modal modal-center" id="taskDetails">
    <div class="modal-bg" @click="closeModal"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Task</h3>
        <button class="btn btn-control close-btn" @click="closeModal">
          <img src="@/assets/icons/icon-close.png" alt="">
        </button>
      </div>
      <div class="text-center mt-5 pt-5" v-if="ui.loadingTask">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-body pb-0" v-else>
        <div class="modal-body-container mb-0">
          <!-- subtask form -->
          <div class="sub-task-form" v-if="fields.task.showForm">
            <div class="form_group w-75">
              <input type="text" class="form-control" v-model="fields.task.title"
                     placeholder="e.g. Change loan amount to 250k">
            </div>
            <div class="form-group mt-2">
              <button type="button" class="btn btn-primary me-2"
                      @click="updateTitle"
                      :disabled="fields.task.title.trim() === ''">
                <span v-if="fields.task.loading" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                <span v-if="!fields.task.loading">Save</span>
              </button>
              <button type="button" class="btn btn-subtask-cancel ms-2"
                      @click="fields.task.showForm=false, fields.task.title=''">Cancel
              </button>
            </div>
          </div>
          <!-- end subtask form -->

          <ResponseError
            :status="error.status"
            :title="error.title"
            :details="error.details"
            :errors="error.errors"
            @hide="hideError"
          />

          <div class="form-group form-check form-check-round full-width task" v-if="!fields.task.showForm">
            <input type="checkbox" class="form-check-input" aria-label="task-status" :checked="true"
                   v-if="task.status === 'done'" @click="markTaskPending"
                   :disabled="!task.isGranted && !task.isGranted.markAsPending"
                   content="Mark task as pending"
                   v-tippy="{arrow: true, placement: 'right-center'}">
            <input type="checkbox" class="form-check-input" aria-label="task-status" :checked="false"
                   v-else @click="markTaskDone" :disabled="!task.isGranted && !task.isGranted.markAsCompleted"
                   content="Mark task as completed"
                   v-tippy="{arrow: true, placement: 'right-center'}">
            <div class="task-title">
              <h6 :class="task.status === 'done' ? 'completed' : ''"
                  @click="fields.task.showForm=true, fields.task.title=task.title">
                {{ task.title }}
              </h6>
              <div class="created-task">Created on {{ task.createdAt | formatDate }}</div>
            </div>
          </div>
        </div>

        <div class="page-tabs d-flex justify-content-between p-0">
          <ul class="tab-list p-0">
            <li :class="{ active: tab === 'subTask' }" @click="setTab('subTask')">
              Sub-tasks <span class="tab-list-item--count">{{ task.subtasks.length }}</span>
            </li>
            <li :class="{ active: tab === 'Comments' }" @click="setTab('Comments')">
              Comments <span class="tab-list-item--count">{{ task.comments.length }}</span>
            </li>
            <li :class="{ active: tab === 'Attachments' }" @click="setTab('Attachments')">
              Attachments <span class="tab-list-item--count">{{ getTotalAttachments(task.comments) }}</span>
            </li>
          </ul>
        </div>
        <div class="row tab-content" data-content="4" v-if="attachments.active">
          <div class="col-12">
            <div class="drag-drop my-5">
              <div class="content">
                <h3 class="drag-text">
                  Drag & Drop files here
                </h3>
              </div>
              <div class="form-group full-width">
                <input type="file" multiple="multiple" @change="manageAttachments">
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3" v-if="!attachments.active">
          <div class="col-md-7">
            <div class="modal-body-container pb-3 border-0" v-if="tab === 'subTask'">
              <div class="subtask">
                <div class="col-12 modal-alert modal-alert_advice text-center" v-if="task.subtasks.length === 0">
                  There's no sub-tasks yet
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between"
                      v-for="subtask in task.subtasks" :key="subtask.id" :id="'subTask_'+subtask.id"
                      @mouseover="showSubTaskAction(subtask.id)"
                      @mouseleave="hideSubTaskAction(subtask.id)">
                    <div class="form-group form-check">
                      <input type="checkbox" class="form-check-input"
                             :id="'fieldCheckSubTask_' + subtask.id"
                             :checked="subtask.status === 'done'"
                             @click="markSubTask(subtask.id)"
                             :content="(subtask.status === 'done') ? 'Mark task as pending' : 'Mark task as completed'"
                             v-tippy="{arrow: true, placement: 'right-center'}">
                      <label :for="'fieldCheckSubTask_' + subtask.id"
                             :class="subtask.status === 'done' ? 'completed' : ''">
                        {{ subtask.title }}
                      </label>
                    </div>
                    <div class="float-right d-none">
                      <button type="button" class="btn btn-control me-2" @click="deleteSubTask(subtask.id)">
                        <img src="@/assets/icons/delete.svg" alt="">
                      </button>
                      <button type="button" class="btn btn-control" @click="showEditSubTask(subtask.id, subtask.title)">
                        <img src="@/assets/icons/icon-edit-normal.svg" alt="">
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="my-2">
                <div class="modal-body-container sub-task-form" v-if="subTask.showForm === true">
                  <div class="form_group">
                    <input type="text" class="form-control" v-model="subTask.formData.title"
                           placeholder="e.g. Change loan amount to 250k">
                  </div>
                  <div class="form-group mt-2">
                    <button type="button" class="btn btn-primary me-2"
                            :disabled="subTask.formData.title.trim() === ''"
                            @click="addSubTask">
                      <span v-if="subTask.formData.loading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                      <span v-if="!subTask.formData.loading && subTask.action === 'add'">Add Task</span>
                      <span v-if="!subTask.formData.loading && subTask.action === 'edit'">Update Task</span>
                    </button>
                    <button type="button" class="btn btn-subtask-cancel ms-2" @click="cancelSubTask">Cancel</button>
                  </div>
                </div>
                <a href="#" v-if="subTask.showForm === false"
                   @mouseover="subTask.hover = true"
                   @mouseleave="subTask.hover = false"
                   @click="subTask.showForm = true">
                  <img v-if="subTask.hover === true" src="@/assets/icons/icon-add-blue-hover.svg" alt="">
                  <img v-if="subTask.hover === false" src="@/assets/icons/icon-add-blue.svg" alt="">
                  Add sub-task
                </a>
              </div>
            </div>
            <div class="modal-body-container border-0 py-0 my-0" v-show="tab === 'Comments'">
              <div class="comments" id="modal-body-scroll">
                <div class="col-12 modal-alert modal-alert_advice text-center" v-if="task.comments.length === 0">
                  There's no comments yet
                </div>
                <div class="comment row mt-1" v-for="comment in task.comments" :key="comment.id">
                  <div class="commenter-avatar col-2 pe-0 text-center">
                    <img :src="agentPhoto(comment.author.photo)" alt="">
                  </div>
                  <div class="comment-body col-10">
                    <div class="row">
                      <div class="col-9 ps-0">
                      <span class="commenter-name">
                        {{ comment.author.fullName }}
                      </span>
                      </div>
                      <div class="col-3 text-end comment-date ps-0">
                        {{ comment.createdAt | formatDate }}
                      </div>
                      <div class="col-12 comment-text ps-0">
                        {{ comment.text }}
                      </div>
                      <div class="col-12 ps-0" v-for="(doc, index) in comment.documents" :key="index">
                        <div class="attach-file">
                          <div class="">
                            <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                            <span class="file-name">{{ doc.clientFilename }}</span>
                          </div>
                          <button @click="downloadDocument(doc.id, doc.clientFilename)">
                            <img src="@/assets/icons/icon-download.svg" alt="download">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body-container border-0 py-0 my-0" v-show="tab === 'Attachments'">
              <div class="comments" >
                <div class="col-12 modal-alert modal-alert_advice text-center" v-if="task.attachments.length === 0">
                  There's no attachments yet
                </div>
                <div class="comment row" v-for="attach in task.attachments" :key="attach.key">
                  <div class="commenter-avatar col-2 pe-0 text-center">
                    <img :src="agentPhoto(attach.author.photo)" alt="">
                  </div>
                  <div class="comment-body col-10">
                    <div class="row">
                      <div class="col-9 ps-0">
                        <span class="commenter-name">
                          {{ attach.author.fullName }}
                        </span>
                      </div>
                      <div class="col-3 ps-0 text-end comment-date">
                        {{ attach.createdAt | formatDate }}
                      </div>
                      <div class="col-12 ps-0 comment-text">
                        {{ attach.text }}
                      </div>
                      <div class="col-12 ps-0" v-for="(doc, index) in attach.documents" :key="index">
                        <div class="attach-file">
                          <div class="">
                            <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                            <span class="file-name">{{ doc.clientFilename }}</span>
                          </div>
                          <button @click="downloadDocument(doc.id, doc.clientFilename)">
                            <img src="@/assets/icons/icon-download.svg" alt="download">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <div class="modal-body-container py-2" v-if="task.loan.id !== null">
              <div class="row">
                <div class="col-12 mb-2" v-if="loanFile.hasEdit">
                  <label for="fieldTaskFile">Loan File</label>
                  <div class="d-flex">
                    <multiselect v-model="task.loan"
                                 track-by="id"
                                 label="borrowerName"
                                 placeholder="Select File"
                                 id="fieldTaskFile"
                                 :options="loans"
                                 :searchable="true"
                                 :close-on-select="true"
                                 :allow-empty="false"
                                 :show-labels="false">
                    </multiselect>
                    <button type="button" class="btn btn-control btn-changeLoan btn-outline-success btn-sm"
                            @click="changeLoanFile">
                      <img v-if="!loanFile.loading" src="@/assets/icons/icon-add-icon-value-added.svg" alt="">
                      <span v-if="loanFile.loading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between" v-else>
                  <div class="d-flex align-items-center">
                    <v-avatar :username="task.loan.borrowerName" :size="42" rounded/>
                    <div class="ms-2">
                      <label class="mb-0" for="LoanName">Loan File</label>
                      <div id="LoanName">{{ task.loan.borrowerName }}</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <img src="@/assets/icons/icon-edit-normal.svg" alt="" class="icon" @click="loanFile.hasEdit=true">
                    <button type="button" class="btn btn-control" @click="deleteTask"
                            v-if="task.isGranted && task.isGranted.delete">
                      <img src="@/assets/icons/delete.svg" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body-container py-2">
              <div class="row">
                <div class="col-12 mb-2" v-if="assignee.hasEdit === true">
                  <label for="fieldAssignee">Assignee</label>
                  <div class="d-flex">
                    <multiselect v-model="task.assignedTo"
                                 track-by="id"
                                 label="fullName"
                                 placeholder="Select Assignee"
                                 id="fieldAssignee"
                                 :max-height="200"
                                 :options="agents"
                                 :searchable="true"
                                 :close-on-select="true"
                                 :allow-empty="false"
                                 :show-labels="false">
                    </multiselect>
                    <button type="button" class="btn btn-control btn-changeAssignee btn-outline-success btn-sm"
                            @click="changeAssignee">
                      <img v-if="!assignee.loading" src="@/assets/icons/icon-add-icon-value-added.svg" alt="">
                      <span v-if="assignee.loading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
                <div class="d-flex align-items-center" v-if="assignee.hasEdit === false">
                  <img :src="agentPhoto(task.assignedTo.photo)" alt="" class="avatar">
                  <div class="ms-2">
                    <label class="mb-0" for="Assignee">Assignee</label>
                    <div id="Assignee" @click="assignee.hasEdit=true">{{ task.assignedTo.fullName }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-body-container py-2">
              <div class="d-flex align-items-center">
                <img :src="agentPhoto(task.assignedBy.photo)" alt="" class="avatar">
                <div class="ms-2">
                  <label class="mb-0" for="AssignedBy">Assigned by</label>
                  <div id="AssignedBy">{{ task.assignedBy.fullName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start"
           :class="{'d-block': tab === 'Attachments', 'd-block': tab === 'Comments', 'd-none': tab === 'subTask' }"
           v-if="ui.loadingTask === false && !attachments.active">
        <div class="form-group full-width">
          <textarea name=""
                    cols="30"
                    rows="3"
                    id="fieldComment1"
                    class="form-control"
                    v-model="comment"
                    placeholder="Write a comment" aria-label="Comment">
          </textarea>
        </div>
        <div class="form-group full-width mt-0" v-if="attachments.files.length > 0">
          <div class="row">
            <div class="col-12" v-for="(file, index) in attachments.files" :key="index">
              <div class="attach-file">
                <span class="file-name">{{ file.name }}</span>
                <button class="btn btn-control" @click="removeAttachment(index)">
                  <img src="@/assets/icons/delete.svg" alt="Delete">
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 w-100">
          <div class="col-6">
            <button class="btn btn-control" @click="attachments.active=true">
              <img src="@/assets/icons/icon-attachment-18.svg" alt="">
            </button>
          </div>
          <div class="col-6 text-end pe-0">
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-primary m-0" @click="addComment" :disabled="loadComment || comment.trim() === ''">
                <span v-if="loadComment" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="!loadComment">Add Comment</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import moment from "moment";
import PerfectScrollbar from 'perfect-scrollbar';
import ResponseError from "@/views/tasks/includes/ResponseError";

export default {
  name: "TaskModal",
  components: {Multiselect, ResponseError},
  data() {
    return {
      ui: {
        loadingTask: false,
        ps: null
      },
      tab: this.$store.state.taskModal.tabName,
      taskId: this.$store.state.taskModal.taskId,
      task: {},
      loans: [],
      agents: [],
      // old ...
      attachments: {
        count: Number,
        files: [],
        active: false
      },
      modal: this.$store.state.TaskModal,
      comment: '',
      loadComment: false,
      subTask: {
        id: null,
        hover: false,
        showForm: false,
        action: 'add',
        formData: {
          loading: false,
          title: '',
          editFlag: false
        }
      },
      loanFile: {
        file: {
          value: Number,
          label: String
        },
        list: [],
        hasEdit: false,
        loading: false
      },
      assignee: {
        agent: {
          value: Number,
          label: String
        },
        list: [],
        hasEdit: false,
        loading: false
      },
      fields: {
        task: {
          title: null,
          showForm: false,
          loading: false
        }
      },
      error: {
        status: false,
        title: '',
        details: '',
        errors: []
      },
      oldData: []
    }
  },
  methods: {
    getTotalAttachments(comments) {
      let total = 0;

      comments.filter(comment => {
        total += comment.documents.length
      })

      return total
    },
    setTab(tabName) {
      this.tab = tabName;
      if (tabName === 'Comments') {
        this.$nextTick(() => {
          const scrollbar = document.getElementById('modal-body-scroll');
          this.ui.ps = new PerfectScrollbar(scrollbar, {
            useBothWheelAxes: false,
            suppressScrollX: true
          });
        });
      }
      if (this.attachments.active) {
        this.attachments.active = false
        this.attachments.files = []
      }
    },
    taskLoad() {
      this.ui.loadingTask = true
      this.$http.get(`/api/v1/tasks/show/${this.taskId}`)
       .then((res) => {
        this.task = res.data.task
        this.loans = res.data.loans
        this.agents = res.data.agents

        let attachments = [];
        if (this.task.comments.length > 0) {
          this.task.comments.map(function (value) {
            if (value.documents.length > 0) {
              attachments.push(value)
            }
          })
        }
        this.task.attachments = attachments

        // Store data in Old variable
        this.oldData.push(this.task);
        this.oldData.push(this.loans);
        this.oldData.push(this.task.attachments);
      })
      .catch((err) => {
        if (err.response.data.status === 500) {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Internal Server Error.',
            status: 'error',
            delay: 2000
          });
        } else {
          this.error.status= true
          this.error.title = err.response.data.title.trim()
          this.error.details = err.response.data.detail.trim()
        }
      })
      .finally(() => {
        this.ui.loadingTask = false
        if (this.tab === 'Comments') {
          this.$nextTick(() => {
            const scrollbar = document.getElementById('modal-body-scroll');
            this.ui.ps = new PerfectScrollbar(scrollbar, {
              useBothWheelAxes: false,
              suppressScrollX: true
            });
          });
        }
      })
    },
    markTaskPending() {
      this.$http
        .get(`/api/v1/tasks/mark/pending/${this.taskId}`)
        .then(() => {})
        .catch((err) => {
          if (err.response.data.status === 500) {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Internal Server Error.',
              status: 'error',
              delay: 2000
            });
          } else {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: err.response.data.detail.trim(),
              status: 'error',
              delay: 2000
            });
          }
        })
        .finally(() => {
          this.taskLoad()
        })
    },
    markTaskDone() {
      this.$http
        .get(`/api/v1/tasks/mark/completed/${this.taskId}`)
        .then(() => {})
        .catch((err) => {
          if (err.response.data.status === 500) {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Internal Server Error.',
              status: 'error',
              delay: 2000
            });
          } else {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: err.response.data.detail.trim(),
              status: 'error',
              delay: 2000
            });
          }
        })
        .finally(() => {
          this.taskLoad()
        })
    },
    deleteTask() {
      if (confirm("Are you sure?")) {
        this.ui.loadingTask = true
        this.$http
          .delete(`/api/v1/tasks/delete/${this.taskId}`)
          .then(() => {
            this.closeModal()
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Task deleted successfully!',
              status: 'success',
              delay: 2000
            });
          })
          .catch((err) => {
            if (err.response.data.status === 500) {
              this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
                msg: 'Internal Server Error.',
                status: 'error',
                delay: 2000
              });
            } else {
              this.error.status= true
              this.error.title = err.response.data.title.trim()
              this.error.details = err.response.data.detail.trim()
            }
          })
          .finally(() => {
            this.ui.loadingTask = false
          })
      }
    },
    deleteSubTask(id) {
      if (confirm("Are you sure?")) {
        this.ui.loadingTask = true
        this.$http
          .delete(`/api/v1/tasks/subtasks/delete/${id}`)
          .then(() => {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Sub-Task deleted successfully!',
              status: 'success',
              delay: 2000
            });
            this.taskLoad()
          })
      }
    },
    showEditSubTask(id, title) {
      this.subTask.action = 'edit'
      this.subTask.formData.title = title
      this.subTask.id = id
      this.subTask.showForm = true
    },
    changeLoanFile() {
      this.loanFile.loading = true
      this.$http
        .post(`/api/v1/tasks/reassign/loan/${this.taskId}`, {loanId: this.task.loan.id})
        .then(() => {
          this.loanFile.loading = false
          this.loanFile.hasEdit = false
          this.taskLoad()
        })
    },
    changeAssignee() {
      this.assignee.loading = true
      this.$http
        .post(`/api/v1/tasks/reassign/assignee/${this.taskId}`, {agentId: this.task.assignedTo.id})
        .then(() => {
          this.assignee.loading = false
          this.assignee.hasEdit = false
          this.taskLoad()
        })
        .catch((err) => {
          if (err.response.data.status === 500) {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Internal Server Error.',
              status: 'error',
              delay: 2000
            });
          } else {
            this.error.status= true
            this.error.title = err.response.data.title.trim()
            this.error.details = err.response.data.detail.trim()
          }
        })
        .finally(() => {
          this.assignee.loading = false
        })
    },
    closeModal() {
      // Compare the old and new data
      if (this.isChangesExist()) {
        this.$root.$emit('load-tasks')
        this.$root.$emit('load-notes-tasks')
      }

      this.toggleTaskModal()
    },
    isChangesExist () {
      let newData = [];
      newData.push(this.task);
      newData.push(this.loans);
      newData.push(this.task.attachments);

      return JSON.stringify(this.oldData) != JSON.stringify(newData);
    },
    showSubTaskAction(id) {
      let target = document.getElementById("subTask_" + id);
      target.children[1].classList.remove("d-none");
      target.children[1].classList.add("d-flex");
    },
    hideSubTaskAction(id) {
      let target = document.getElementById("subTask_" + id);
      target.children[1].classList.remove("d-flex");
      target.children[1].classList.add("d-none");
    },
    downloadDocument(id, filename) {
      this.$http
        .get(`/api/v1/task/comments/download/${id}`,  { responseType: 'blob' })
        .then(res => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data],{type: res.headers['content-type']} ));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch()
    },
    markSubTask(id) {
      this.$http
        .get(`/api/v1/tasks/subtasks/mark/${id}`)
        .then(() => this.taskLoad())
    },
    addSubTask() {
      this.subTask.formData.loading = true
      let url = `/api/v1/tasks/subtasks/new/${this.taskId}`
      let response = 'Sub-Task added successfully!'
      if (this.subTask.action === 'edit') {
        url = `/api/v1/tasks/subtasks/edit/${this.subTask.id}`
        response = 'Sub-Task updated successfully!'
      }
      let formData = new FormData()
      formData.append('title', this.subTask.formData.title)
      this.$http.post(url, formData).then(() => {
        this.subTask.formData.loading = false
        this.subTask.id = null
        this.subTask.action = 'add'
        this.subTask.showForm = false
        this.subTask.formData.title = ''
        this.taskLoad()
        this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
          msg: response,
          status: 'success',
          delay: 2000
        });
      })
    },
    // ...
    addComment() {
      this.loadComment = true;

      if (this.ui.ps !== null) {
        this.ui.ps.destroy();
        this.ui.ps = null;
      }
      let formData = new FormData()
      formData.append('comment', this.comment)
      for (let file of this.attachments.files) {
        formData.append(`file[]`, file)
      }
      this.$http.post(`/api/v1/task/comments/new/${this.taskId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.comment = ''
        this.attachments.files = []
        this.taskLoad()
      })
      .catch((err) => {
        this.error.status = true
        if (err.response.data.errors) {
          this.error.title = err.response.data.message.trim()
          this.error.errors = err.response.data.errors
        } else if (err.response.data.status === 500) {
          this.hideError()
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Internal Server Error.',
            status: 'error',
            delay: 2000
          });
        } else {
          this.error.title = err.response.data.title.trim()
          this.error.details = err.response.data.detail.trim()
        }
      })
      .finally(() => {
        this.loadComment = false
      })
    },
    hideError() {
      this.error = {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    },
    cancelSubTask() {
      this.subTask.action = 'add'
      this.subTask.formData.title = ''
      this.subTask.id = null
      this.subTask.showForm = false
    },
    updateTitle() {
      this.fields.task.loading = true
      let formData = new FormData()
      formData.append('title', this.fields.task.title)
      this.$http.post(`/api/v1/tasks/update/title/${this.taskId}`, formData).then(() => {
        this.fields.task.loading = false
        this.fields.task.showForm = false
        this.fields.task.title = null
        this.taskLoad()
      })
    },
    manageAttachments(event) {
      this.attachments.active = false
      this.attachments.files = Array.from(event.target.files)
    },
    removeAttachment(index) {
      this.$delete(this.attachments.files, index)
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('MMM D, Y')
      }
    }
  },
  beforeMount() {
    this.taskLoad();
  },
  beforeDestroy() {
    this.$store.dispatch('CLOSE_TASK_MODAL')
  }
}
</script>
<style lang="scss" scoped>
.modal {
  &.modal-center {
    .modal-block {
      border-radius: 8px;
      margin-top: 2.5%;
      overflow-y: auto;
    }

    .modal-footer {
      position: inherit;
      bottom: -7%;
    }
  }

  .task {
    .created-task {
      opacity: 0.46;
      color: rgba(0, 0, 0, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      margin-top: 10px;
    }

    &.form-check {
      label {
        color: rgba(0, 0, 0, 1);
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 27px;
      }
      input {
        border: 2px solid rgba(1,122,255,1);
        background-color: rgba(1,122,255,0.1);
      }
    }

    .task-title {
      .completed {
        text-decoration: line-through;
        opacity: 0.46;
      }
    }
  }

  .form-check {
    label {
      color: rgba(0, 0, 0, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      margin: 0;
    }
  }

  &-body {
    padding: 90px 30px;

    @media (max-width: 568px) {
      padding: 90px 10px;

      .page-tabs .tab-list li {
        font-size: 10px;
      }
    }
  }

  .attach-file {
    height: 36px;
    max-width: 100%;
    width: 100%;
    margin: 10px 0 0 0;
  }

  .comments {
    max-height: 380px;
    min-height: 380px;
    overflow-x: hidden;
  }

  .commenter-avatar {
    img {
      height: 37px;
      width: 37px;
      border: 1px solid rgba(237, 237, 238, 1);
      border-radius: 50%;
    }
  }

  .comment-body {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(239, 239, 239, 1);
  }

  .commenter-avatar {
    color: rgba(0, 0, 0, 1);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }

  .comment-text {
    color: rgba(0, 0, 0, 1);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    opacity: 0.46;
  }

  .comment-date {
    opacity: 0.46;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .file-name {
    font-size: 10px;
  }

  .modal-body-container {
    margin-bottom: 0;

    .form-check {
      label {
        color: rgba(0, 0, 0, 1);
        font-size: 11px;
        letter-spacing: 0;
        line-height: 11px;
        margin: 0;
      }
    }

    .form-check-input {
      max-width: 18px;
      width: 100%;
      height: 18px;
      border-radius: 50% !important;
      margin-top: 0;
      margin-right: 10px;

      &:checked {
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }

    label {
      color: rgba(0, 0, 0, .7);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      font-weight: normal;
    }
  }

  .subtask {
    border-bottom: 1px solid rgba(239, 239, 239, 1);
    max-width: 100%;
    width: 100%;
    padding-bottom: 10px;

    .completed {
      text-decoration: line-through;
      opacity: 0.46;
    }
  }

  .avatar {
    box-sizing: border-box;
    height: 43px;
    width: 43px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
  }

  .loan-avatar {
    div {
      text-align: center;
      padding-top: .6rem;
      color: #FFF;
      border: solid 1px #FFF;
      border-radius: 50%;
      background-color: #2DB961;
      height: 42px;
      width: 42px;
    }
  }

  a {
    color: #000;
    opacity: 0.46;
    text-decoration: none;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 13px;

    &:hover {
      opacity: 1;
    }
  }

  .sub-task-form {
    input {
      height: auto;
    }

    .btn-subtask-cancel {
      opacity: 0.46;
      color: #000000;
      font-family: Poppins;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      transition: all .3s;

      &:hover {
        background: lightgrey;
      }
    }
  }

  .btn-changeLoan, .btn-changeAssignee {
    margin: 6px 3px;
    border-radius: 8px;

    &:hover {
      background-color: #FFF;
    }
  }

  .list-group-item {
    min-height: 3.1rem;
  }

  #Assignee, .task-title h6 {
    cursor: pointer;
  }

  .drag-drop {
    width: 100%;
    height: 465px;
    border: 1px dashed rgba(138, 138, 138, 1);
    border-radius: 4px;
    background-color: rgba(243, 243, 244, 1);

    .content {
      height: 465px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
    }

    .drag-text {
      opacity: 0.46;
      color: #000000;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
    }

    .form-group {
      width: 100%;
      height: 100%;

      input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 0;
        cursor: copy;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
